import { useNavigation } from "@remix-run/react";
import { useEffect, useState } from "react";
import { BooleanCheck } from "~/utils/BooleanCheck";

type MingleSelectProps = {
  name?: string;
  label?: string;
  placeHolder?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactNode;
  options?: options[];
  defaultOption?: options;
  value?: string;
  defaultValue?: string | number;
  isLarge?: boolean;
  disabled?: boolean;
  classNames?: string;
};

export type options = {
  label: string;
  value: string;
  playerLabels?: [];
};
export const MingleSelect = ({
  name,
  label,
  placeHolder,
  error,
  value,
  defaultValue,
  isLarge,
  disabled = false,
  onChange = () => {},
  startIcon,
  endIcon,
  classNames,
  options,
  defaultOption,
}: MingleSelectProps) => {
  const [allOptions, setAllOptions] = useState<options[]>(options || []);
  useEffect(() => {
    if (defaultOption && options) setAllOptions([defaultOption, ...options]);
    else if (options) setAllOptions(options);
  }, [defaultOption, options]);
  let navigation = useNavigation();
  return (
    <div
      className={`relative w-full  max-w-md ${classNames ? classNames : ""}`}
    >
      <label
        htmlFor={name}
        className="block text-xs font-medium mb-1 text-gray-800 dark:text-gray-200 w-full"
      >
        {label}
      </label>
      <select
        {...{
          id: name,
          name,
          ...(value ? { value } : {}),
          ...(defaultValue ? { defaultValue } : {}),
          ...(defaultValue ? { key: defaultValue } : {}),
          ...(disabled
            ? { disabled }
            : { disabled: navigation.state !== "idle" }),

          "aria-invalid": error ? "true" : "false",
          placeholder: placeHolder,
          onChange: (e) => {
            onChange(e);
          },
        }}
        className={`input input-bordered w-full text-left input-secondary text-gray-500 dark:text-gray-200 border-gray-300 dark:border-gray-900 focus:border-green-500 focus:outline-offset-0 focus:outline-green-200 focus:outline-4 [color-scheme:light] dark:[color-scheme:dark] ${
          error ? "border-red-500  dark:border-red-500" : ""
        } ${isLarge ? "lg:font-[700] lg:text-2xl bg-black" : ""} ${
          startIcon ? "pl-12" : ""
        }  ${endIcon ? "pr-8" : ""}`}
        style={{ WebkitAppearance: "none", appearance: "none" }}
      >
        {allOptions?.map((option, index) => {
          if (option) {
            return (
              <option key={option.value + index} value={option.value}>
                {option.label}
              </option>
            );
          }
        })}
      </select>
      {startIcon && (
        <div
          className={`absolute w-10 h-8 ${
            error ? "bottom-12" : "bottom-2"
          } left-3 pr-3 flex items-center pointer-events-none`}
        >
          {startIcon}
        </div>
      )}{" "}
      {endIcon && (
        <div
          className={`absolute w-10 h-8 ${
            error ? "bottom-12" : "bottom-2"
          } -right-3 pr-3 flex items-center pointer-events-none`}
        >
          {endIcon}
        </div>
      )}
      {error && (
        <label className="label">
          <p className="mr-auto text-sm font-medium text-red-500">{error}</p>
        </label>
      )}
    </div>
  );
};
